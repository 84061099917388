@font-face {
  font-family: VazirEn;
  src: url("Vazir-Variable.woff2") format("woff2"), url("Vazir-Variable.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: VazirEn;
  src: url("Vazir-Variable.woff2") format("woff2"), url("Vazir-Variable.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: VazirEn;
  src: url("Vazir-Thin.woff2") format("woff2"), url("Vazir-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: VazirEn;
  src: url("Vazir-Thin.woff2") format("woff2"), url("Vazir-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: VazirEn;
  src: url("Vazir-Variable.woff2") format("woff2"), url("Vazir-Variable.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: VazirEn;
  src: url("Vazir-Regular.woff2") format("woff2"), url("Vazir-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: VazirEn;
  src: url("Vazir-Thin.woff2") format("woff2"), url("Vazir-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: VazirEn;
  src: url("Vazir-Regular.woff2") format("woff2"), url("Vazir-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: VazirEn;
  src: url("Vazir-Medium.woff2") format("woff2"), url("Vazir-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: VazirEn;
  src: url("Vazir-Regular.woff2") format("woff2"), url("Vazir-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: VazirEn;
  src: url("Vazir-Medium.woff2") format("woff2"), url("Vazir-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: VazirEn;
  src: url("Vazir-Light.woff2") format("woff2"), url("Vazir-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: VazirEn;
  src: url("Vazir-Light.woff2") format("woff2"), url("Vazir-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: VazirEn;
  src: url("Vazir-Medium.woff2") format("woff2"), url("Vazir-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: VazirEn;
  src: url("Vazir-Bold.woff2") format("woff2"), url("Vazir-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: VazirEn;
  src: url("Vazir-Light.woff2") format("woff2"), url("Vazir-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: VazirEn;
  src: url("Vazir-Bold.woff2") format("woff2"), url("Vazir-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: VazirEn;
  src: url("Vazir-Black.woff2") format("woff2"), url("Vazir-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: VazirEn;
  src: url("Vazir-Black.woff2") format("woff2"), url("Vazir-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: VazirEn;
  src: url("Vazir-Bold.woff2") format("woff2"), url("Vazir-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: VazirEn;
  src: url("Vazir-Black.woff2") format("woff2"), url("Vazir-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
