@font-face {
  font-family: Vazir;
  src: url("Vazir-Thin-FD.woff2") format("woff2"), url("Vazir-Thin-FD.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Vazir;
  src: url("Vazir-Thin-FD.woff2") format("woff2"), url("Vazir-Thin-FD.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Vazir;
  src: url("Vazir-Regular-FD.woff2") format("woff2"), url("Vazir-Regular-FD.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Vazir;
  src: url("Vazir-Thin-FD.woff2") format("woff2"), url("Vazir-Thin-FD.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Vazir;
  src: url("Vazir-Regular-FD.woff2") format("woff2"), url("Vazir-Regular-FD.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Vazir;
  src: url("Vazir-Medium-FD.woff2") format("woff2"), url("Vazir-Medium-FD.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Vazir;
  src: url("Vazir-Regular-FD.woff2") format("woff2"), url("Vazir-Regular-FD.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Vazir;
  src: url("Vazir-Medium-FD.woff2") format("woff2"), url("Vazir-Medium-FD.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Vazir;
  src: url("Vazir-Light-FD.woff2") format("woff2"), url("Vazir-Light-FD.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Vazir;
  src: url("Vazir-Medium-FD.woff2") format("woff2"), url("Vazir-Medium-FD.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Vazir;
  src: url("Vazir-Light-FD.woff2") format("woff2"), url("Vazir-Light-FD.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Vazir;
  src: url("Vazir-Bold-FD.woff2") format("woff2"), url("Vazir-Bold-FD.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Vazir;
  src: url("Vazir-Light-FD.woff2") format("woff2"), url("Vazir-Light-FD.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Vazir;
  src: url("Vazir-Bold-FD.woff2") format("woff2"), url("Vazir-Bold-FD.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Vazir;
  src: url("Vazir-Bold-FD.woff2") format("woff2"), url("Vazir-Bold-FD.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Vazir;
  src: url("Vazir-Black-FD.woff2") format("woff2"), url("Vazir-Black-FD.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Vazir;
  src: url("Vazir-Black-FD.woff2") format("woff2"), url("Vazir-Black-FD.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Vazir;
  src: url("Vazir-Black-FD.woff2") format("woff2"), url("Vazir-Black-FD.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
